import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './App.css';
import Tabs from "./components/Tabs";
import Button from 'react-bootstrap/Button';


function App() {
  const nombreTransmision = "RESEÑA. American College of Surgeons, Clinical Congress 2024";
  const fechaTransmision = "11 de diciembre, 19:00 hrs.";

  const video = "https://vimeo.com/event/4786649/embed"; //https://player.vimeo.com/video/2386692?h=6718c06abe&title=0&byline=0&portrait=0";
  const chat =  "https://vimeo.com/event/4786649/chat/";
  const ver = false;

  /*
              <div label="Chat">
              <div className='container'>
                <iframe src={chat} width="100%" height="100%" frameborder="0"></iframe>
              </div>
            </div>

            */
  return (
    <Container fluid>
      <Row >
        <Col>
          <div className="header">
            <div className="headertitulo">
              <div >
                <img
                  className='logoh'
                  src={require('./logos-transmisiones.png')}
                  alt='logo amcg' />
              </div>

              <div className="headertexto">
                <p className="nombretransmision">{nombreTransmision}</p>
                <p className="fechatransmision">{fechaTransmision}</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row className='videoseccion'>
        <Col sm >
          <img src={require('./imagen para video.jpg')} className="ImageVideo ocultar" />
          <iframe src={video} width="100%" height="450" frameborder="0" allow="autoplay; fullscreen; picture-in-picture " allowfullscreen className=''></iframe>
          <div className='col-centered channel'>
            <a href="https://amcg.mx/" target="_blank" > <Button variant="primary">Canal 1</Button>{' '}</a>
            <a href="https://amcg2.mx/" target="_blank" > <Button variant="primary">Canal 2</Button>{' '}</a>
          </div>
          <p className='link'>Si no visualiza la transmisión a la hora señalada de la Sesión Académica, actualice la página o de <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={() => window.location.reload()}>clic aquí</span></p>

        </Col>
        <Col sm >
          <Tabs >
            <div label="Chat">
              <div className='container'>
                <iframe src={chat} width="100%" height="100%" frameborder="0"></iframe>
              </div>
            </div>
            <div label="Programa">
              <div className=''>
                <p className='titulotabs'>Programa</p>
                <br />
                <ul className='listatabs'>
                  <li className='lista'>
                    <p className='horatabs'>19:00 – 19:05 hrs.</p>
                    <p className='titulopresentacion'>​Introduccion y bienvenida.</p>
                    <p className='nombredoctores'>Dra. Adriana Santos Manzur.</p>
                    <p className='nombredoctores'>Dra. Karina Sánchez Reyes.</p>
                    <p className='nombredoctores'>Coordinadoras de las Sesiones Académicas 2025.</p>
                  </li>
                  <li className='lista'>
                    <p className='horatabs'>19:05 – 19:20 hrs.</p>
                    <p className='titulopresentacion'>Prehabilitación en cirugía hepatopancreatobiliar.</p>
                    <p className='nombredoctores'>Dr. Vicente Cardona Infante, FACS,</p>
                    <p className='nombredoctores'>​Gobernador del Capítulo México, ACS</p>
                  </li>
                  <li className='lista'>
                    <p className='horatabs'>19:20 – 19:35 hrs.</p>
                    <p className='titulopresentacion'>Actualidades en trauma duodenal.</p>
                    <p className='nombredoctores'>​​Dra. Juliana María Ordoñez FACS, </p>
                    <p className='nombredoctores'>​​Jefe del Servicio de Cirugia Vascular</p>
                    <p className='nombredoctores'>​​Hospital Militar Central Bogota, Colombia</p>
                  </li>
                  <li className='lista'>
                    <p className='horatabs'>19:35 – 19:50 hrs.</p>
                    <p className='titulopresentacion'>Hernias ventrales e inguinales complejas.</p>
                    <p className='nombredoctores'>Flavio Martis de Oliveira, MD, FACS,</p>
                    <p className='nombredoctores'>Presidente Asociación Americana de Hernias</p>
                  </li>
                  <li className='lista'>
                    <p className='horatabs'>19:50 – 20:00 hrs.</p>
                    <p className='titulopresentacion'>Preguntas y respuestas.</p>
                  </li>
                  <li className='lista'>
                    <p className='horatabs'>20:00 – 20:05 hrs.</p>
                    <p className='titulopresentacion'>Mensaje de fin de año.</p>
                    <p className='nombredoctores'>Dr. Javier Carrillo Silva. Presidente AMCG 2024-2025</p>
                  </li>
                </ul>
              </div> 
            </div>
            <div label="¿Qué es la AMCG?">
              <img className='logoh2'
                src={require('./logo.png')}
                alt='logo amcg' />
              <p className='titulotabs'>¿Qué es la AMCG?</p>
              <p className='textotabs'>La Asociación Mexicana de Cirugía General, A. C.,
                es una organización integrada por cirujanos generales con título
                reconocido por las autoridades competentes. Dentro de sus finalidades,
                proclama la necesidad de pugnar por una mejor preparación científica,
                técnica, cultural, moral, legal y social de todos sus Asociados para
                optimizar las capacidades del profesionista, a fin de ejercer sus
                labores dentro de un marco ético, legal y del más alto nivel técnico
                que demanda esta especialidad. Como grupo social que realiza sus
                actividades para lograr la salud y bienestar del ser humano y la sociedad,
                se esforzará porque sus miembros actúen con la responsabilidad que nuestra
                especialidad exige.
                <br />
                <a href='https://amcg.org.mx/' target="_blank">Más información →</a>
              </p>
            </div>
          </Tabs>

        </Col>
      </Row>
      <Row className='constancia'>
        <Col>
          <p className='titulocons'>Solicite su constancia</p>
          <p className='textocons'>El formulario de registro de constacias se habilita el mismo día de la sesión Académica</p>
          <div className='col-centered'>
            <a href='https://constancias.amcg.mx/registros/' target="_blank" > <Button variant="warning">Ir a registro</Button>{' '}</a>

          </div>


        </Col>
      </Row>
    </Container>

  );
}

export default App;
